/* Different font weights */
.ic-plus3:before {
  content: "\002B";
}
.area-intro {
  color: #ffffff;
  margin-bottom: 3.125rem;
}
.area-intro .intro__txt .headline,
.area-intro .intro__txt .preheadline,
.area-intro .intro__txt .subheadline {
  color: currentColor;
}
.intro-text {
  font-family: 'FrutigerNextLTW06-Medium', Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.875rem;
}
@media screen and (min-width: 1280px) {
  .intro-text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.intro-text .headline {
  font-family: 'FrutigerNextLTW06-Bold', Arial, Helvetica, sans-serif;
  font-weight: 900;
  margin-bottom: 1.5625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.intro-text .preheadline {
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.5rem 1rem 0.25rem;
  border-radius: 3.125rem;
  margin-bottom: 1rem;
  display: flex;
}
.intro-text--no-background .preheadline {
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  background: transparent;
  color: #000000;
  padding: 0;
}
.bg-red .intro-text--no-background .preheadline {
  color: #ffffff;
}
.intro__content {
  padding: 5rem 0;
  align-items: flex-end;
}
@media screen and (min-width: 1280px) {
  .intro__content {
    padding: 9.375rem 0;
  }
}
.intro__header-img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20% 0;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .intro__header-img {
    object-position: 10% 0;
  }
}
@media screen and (min-width: 1280px) {
  .intro__header-img {
    object-position: 0;
  }
}
.intro__title {
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-weight: 900;
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.intro__title--xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.intro__title--pre,
.intro__title--sub {
  max-width: 60%;
  min-width: 15.625rem;
  display: block;
  margin: 0 auto 1rem;
}
@media screen and (min-width: 920px) {
  .intro__title--pre,
  .intro__title--sub {
    max-width: 28.75rem;
  }
}
.intro__title--highlight {
  font-size: 4.875rem;
  line-height: 5.875rem;
  display: block;
  text-align: center;
}
.intro__title--xl .intro__title--highlight {
  font-size: 4.875rem;
  line-height: 5.875rem;
}
@media screen and (min-width: 920px) {
  .intro__title--highlight {
    font-size: 4.0625rem;
    line-height: 4.0625rem;
  }
  .intro__title--xl .intro__title--highlight {
    font-size: 10rem;
    line-height: 12rem;
  }
}
.button-row {
  text-align: center;
  margin-top: 1.5625rem;
}
.intro__txt {
  margin: 0 auto;
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  max-width: 48.125rem;
  text-align: center;
  color: #ffffff;
}
.intro__txt a {
  color: #ffffff;
}
.intro__video {
  margin-top: 3rem;
}
@media screen and (min-width: 920px) {
  .intro__video {
    margin-top: 6.375rem;
  }
}
.intro__header {
  position: relative;
}
.intro__header .intro__content-wrapper {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
}
.intro__header .intro__content-wrapper.justify-items--left {
  justify-content: flex-start;
}
.intro__header .intro__content-wrapper.justify-items--right {
  justify-content: flex-end;
}
.intro__header:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: var(--gradient);
  background-blend-mode: multiply;
  pointer-events: none;
}
.theme-commercial .intro__header:after {
  --gradient: linear-gradient(245.77deg, rgba(71, 37, 131, 0) 0%, rgba(71, 37, 131, 0.6) 100.23%);
}
.theme-technical .intro__header:after {
  --gradient: linear-gradient(245.77deg, rgba(235, 94, 94, 0) 0%, rgba(235, 94, 94, 0.6) 100.23%);
}
.theme-railway-engineering .intro__header:after {
  --gradient: linear-gradient(245.77deg, rgba(36, 73, 154, 0) 0%, rgba(36, 73, 154, 0.6) 100.23%);
}
.video {
  background: #ffffff;
}
.video-container {
  width: 100%;
  aspect-ratio: 1.77777778;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  border-radius: 1.875rem;
}
.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.video-disclaimer {
  padding: 20px 20px 0;
  border: 1px solid #000;
  margin-bottom: 20px;
  background: #ffffff;
  color: #000000;
}
.video__disclaimer-txt {
  margin-bottom: 10px;
}
.video__disclaimer-btn {
  margin-bottom: 20px;
  cursor: pointer;
}
.video__preview-teaser {
  position: relative;
  width: 100%;
  aspect-ratio: 1.77777778;
  overflow: hidden;
  border-radius: 1.875rem;
  margin-bottom: 2rem;
}
.video__preview-teaser:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.875rem;
  background: #000000;
  opacity: 0.3;
}
.video__play-btn {
  display: block;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 1;
  background: none;
  border: none;
  transition: transform 0.35s ease;
  cursor: pointer;
}
.video__play-btn:hover,
.video__play-btn:focus {
  transform: translate(-50%, -50%) scale(1.1);
}
.video__play-btn .nasicher-icon {
  width: 110px;
  height: 110px;
}
.video__preview-image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.component--video-teaser,
.component--bild-teaser {
  padding-top: 0;
}
.bild-teaser,
.video-teaser {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1.5625rem;
  border-top: 2px solid var(--primary-color);
  padding: 3.125rem 0 0;
  margin: 0 auto;
  max-width: 65.625rem;
}
.bild-teaser__txt,
.video-teaser__txt {
  max-width: 37.5rem;
}
@media screen and (min-width: 920px) {
  .bild-teaser,
  .video-teaser {
    grid-template-columns: 18.75rem 1fr;
  }
}
.video__transcript {
  color: #000000;
  font-size: 1rem;
}
.footer {
  background: #ffffff;
  padding: 46px 0 48px;
}
.service-link-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.service-link-list__item {
  margin-left: 35px;
  display: inline-block;
}
.awards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.awards .awards-img {
  display: inline-block;
  margin: 1rem;
  height: auto;
  max-width: 35vw;
}
@media screen and (min-width: 576px) {
  .awards .awards-img {
    max-height: 120px;
    width: auto;
  }
}
.component--job-folders {
  max-width: none;
}
.component--job-folders > .inner {
  padding: 0;
}
.folder-list {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  width: 100%;
  position: relative;
}
@media screen and (min-width: 768px) {
  .folder-list {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .folder-list {
    overflow-x: auto;
  }
}
.folder-list__item {
  margin: 0 0.9375rem 3.125rem;
  width: 80%;
  flex-shrink: 0;
}
.folder-list__item:first-child {
  margin-left: 0;
}
.folder-list__item:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .folder-list__item {
    width: calc(25% - 22.5px);
  }
}
.folder-list__item .folder-list__item-img {
  margin-bottom: 0.9375rem;
}
.folder-list__item-link {
  transition: transform 0.35s ease, opacity 0.35s ease;
  display: block;
  text-decoration: none;
  position: relative;
  opacity: 0.7;
  text-align: center;
}
.folder-list__item-link:hover,
.folder-list__item-link:focus {
  text-decoration: none;
  opacity: 1;
  transform: scale(1.1);
}
.folder-list__item-link:hover .folder-list__item-img,
.folder-list__item-link:focus .folder-list__item-img,
.folder-list__item-link:hover .folder-list__item-icon,
.folder-list__item-link:focus .folder-list__item-icon,
.folder-list__item-link:hover .folder-list__item-title,
.folder-list__item-link:focus .folder-list__item-title {
  opacity: 1;
}
.folder-list__item-title {
  display: block;
  transition: opacity 0.35s ease;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 0 auto;
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .folder-list__item-title {
    opacity: 0;
  }
}
.folder-list__item-icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.625rem;
  transition: opacity 0.35s ease;
  color: #000000;
  font-size: 1rem;
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.375rem 1rem 0.25rem;
  background: #ffffff;
  border-radius: 3.125rem;
}
@media screen and (min-width: 768px) {
  .folder-list__item-icon {
    opacity: 0;
  }
}
.component--sociallinks {
  padding: 0;
  max-width: none;
}
.component--sociallinks > .inner {
  padding: 0;
}
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-wrap: wrap;
  gap: 0.9375rem;
}
.social-links .nasicher-icon {
  width: 32px;
  height: 32px;
}
.social-links__title {
  margin-bottom: 0;
}
.social-links__list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  list-style: none;
}
.social-links__link {
  color: #ffffff;
  text-decoration: none;
  margin: 0 12.5px;
  font-size: 32px;
}
.social-links__link:hover,
.social-links__link:focus {
  text-decoration: none;
}
.data-box {
  padding: 1rem 1.5rem;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 3.125rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
}
.data-box .title {
  font-family: 'FrutigerNextLTW06-Bold', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: currentColor;
}
@media screen and (min-width: 920px) {
  .data-box {
    padding: 3.125rem 6.75rem;
  }
  .data-box .title {
    font-size: 2.5rem;
  }
}
.data-box table {
  border-collapse: collapse;
  width: 100%;
}
.data-box tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding: 16px 0;
  display: block;
}
@media screen and (min-width: 920px) {
  .data-box tr {
    padding: 0;
    display: table-row;
  }
}
.data-box th,
.data-box td {
  text-align: left;
  padding: 0;
  display: block;
}
@media screen and (min-width: 920px) {
  .data-box th,
  .data-box td {
    padding: 16px 0;
    display: table-cell;
  }
}
.data-box th {
  font-family: 'FrutigerNextLTW06-Regular', Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 20px;
}
.data-box td {
  font-family: 'FrutigerNextLTW06-Medium', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
@media screen and (min-width: 920px) {
  .data-box td {
    padding-left: 20px;
  }
}
.hero-teaser {
  position: relative;
  color: #ffffff;
  /** Slider */
  /** Animations */
  /** Forward/Backward Controls */
  /** Indicators */
  /** Controls */
  /** Property z-index should be higher than in Hero-Teaser elements, because Hero-Teaser can be used as items */
  /** Magnolia edit mode */
}
.edit-mode .hero-teaser {
  height: auto;
}
.hero-teaser .carousel__view {
  margin-bottom: 0;
  display: flex;
  width: 100%;
}
.hero-teaser .carousel {
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: 20px;
}
.hero-teaser .carousel.hide {
  animation: hideTeaser 0.5s forwards;
}
.hero-teaser .carousel__view {
  margin-bottom: 0;
  overflow: hidden;
}
.hero-teaser .carousel__view-wrapper,
.hero-teaser .carousel__view {
  position: relative;
}
.hero-teaser .carousel__list {
  width: 100%;
  min-height: 600px;
}
.hero-teaser .carousel__item .carousel__item-img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 20% 0;
}
@media screen and (min-width: 768px) {
  .hero-teaser .carousel__item .carousel__item-img {
    object-position: 10% 0;
  }
}
@media screen and (min-width: 1280px) {
  .hero-teaser .carousel__item .carousel__item-img {
    object-position: 0 0;
  }
}
.hero-teaser .carousel__item.alignment--default .carousel__item-img {
  object-position: 50%  0;
}
@media screen and (min-width: 1280px) {
  .hero-teaser .carousel__item.alignment--default .carousel__item-img {
    object-position: 0 0;
  }
}
.hero-teaser .carousel__item {
  left: auto;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  --gradient: none;
}
.edit-mode .hero-teaser .carousel__item {
  display: block;
  position: relative;
}
.hero-teaser .carousel__item:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: var(--gradient);
  pointer-events: none;
}
.hero-teaser .carousel__item.carousel-theme--commercial:after {
  --gradient: linear-gradient(245.77deg, rgba(71, 37, 131, 0) 0%, rgba(71, 37, 131, 0.6) 100.23%);
}
.hero-teaser .carousel__item.carousel-theme--technical:after {
  --gradient: linear-gradient(245.77deg, rgba(235, 94, 94, 0) 0%, rgba(235, 94, 94, 0.6) 100.23%);
}
.hero-teaser .carousel__item.carousel-theme--railway-engineering:after {
  --gradient: linear-gradient(245.77deg, rgba(36, 73, 154, 0) 0%, rgba(36, 73, 154, 0.6) 100.23%);
}
.hero-teaser .carousel__item--active {
  top: 0;
  display: block;
  width: 100%;
  transform: translateX(0);
}
.hero-teaser .carousel__item--slide-in-left {
  transform: translateX(-100%);
}
.hero-teaser .carousel__item--slide-in-right {
  transform: translateX(100%);
}
.hero-teaser .carousel__item--slide-out-left {
  transform: translateX(-100%);
}
.hero-teaser .carousel__item--slide-out-right {
  transform: translateX(100%);
}
.hero-teaser .carousel__item.carousel__item--is-sliding {
  position: absolute;
  top: 0;
}
.hero-teaser .carousel__text {
  position: absolute;
  width: 100%;
  padding: 10px;
  bottom: 3.75rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  color: #ffffff;
}
@media screen and (min-width: 920px) {
  .hero-teaser .carousel__text {
    width: auto;
    bottom: 13.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.hero-teaser .carousel__text .headline,
.hero-teaser .carousel__text .preheadline,
.hero-teaser .carousel__text .subheadline,
.hero-teaser .carousel__text .title {
  color: #ffffff;
}
@media screen and (min-width: 920px) {
  .hero-teaser .alignment--left .carousel__text {
    width: auto;
    left: 0;
    right: auto;
    transform: translateX(50%);
  }
}
@media screen and (min-width: 920px) {
  .hero-teaser .alignment--right .carousel__text {
    width: auto;
    left: auto;
    right: 0%;
    transform: translateX(-50%);
  }
}
.hero-teaser .carousel__control-forward,
.hero-teaser .carousel__control-backward {
  position: absolute;
  top: 50%;
  display: none;
  width: 35px;
  height: 35px;
  color: #ffffff;
  cursor: pointer;
  transform: translateY(-50%);
  transition: transform 0.25s ease;
}
.hero-teaser .carousel__control-forward [class*='nasicher-icon'],
.hero-teaser .carousel__control-backward [class*='nasicher-icon'] {
  width: 40px;
  height: 40px;
}
.hero-teaser .carousel__control-forward:hover,
.hero-teaser .carousel__control-backward:hover,
.hero-teaser .carousel__control-forward:focus,
.hero-teaser .carousel__control-backward:focus {
  transform: scale(1.1) translateY(-50%);
}
.hero-teaser .carousel__control-forward {
  right: 0;
}
.hero-teaser .carousel__control-backward {
  left: 0;
}
@media screen and (min-width: 768px) {
  .hero-teaser .carousel__control-backward,
  .hero-teaser .carousel__control-forward {
    display: block;
    width: 65px;
  }
  .hero-teaser .carousel__control-backward [class*='nasicher-icon'],
  .hero-teaser .carousel__control-forward [class*='nasicher-icon'] {
    width: 68px;
    height: 68px;
  }
}
.hero-teaser .carousel__indicators {
  z-index: 1;
  display: block;
  text-align: center;
}
.hero-teaser .carousel__indicator {
  display: inline-block;
  padding: 8px 0;
  background: none;
  border: none;
  cursor: pointer;
}
.hero-teaser .carousel__indicator::after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.hero-teaser .carousel__indicator:focus::after,
.hero-teaser .carousel__indicator:hover::after,
.hero-teaser .carousel__indicator:active::after,
.hero-teaser .carousel__indicator--active::after {
  background: #ffffff;
}
.hero-teaser .carousel__bottom {
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: grid;
  justify-content: center;
}
@media screen and (min-width: 920px) {
  .hero-teaser .carousel__bottom {
    grid-template-rows: 1fr;
    grid-gap: 1.5625rem;
  }
}
.hero-teaser .carousel__hint {
  display: none;
}
@media screen and (min-width: 920px) {
  .hero-teaser .carousel__hint {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1rem;
    animation: moveInBottom 1s ease-out;
    animation-fill-mode: backwards;
  }
  .hero-teaser .carousel__hint svg {
    justify-self: center;
    width: 1.625rem;
    height: 3.25rem;
  }
}
.hero-teaser .carousel__controls {
  display: flex;
  justify-content: center;
}
.hero-teaser .carousel__control-play {
  display: block;
  margin: 0 16px;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: transform 0.25s ease;
}
.hero-teaser .carousel__control-play:hover,
.hero-teaser .carousel__control-play:focus {
  transform: scale(1.1);
}
.hero-teaser .carousel__control-play:link,
.hero-teaser .carousel__control-play:visited,
.hero-teaser .carousel__control-play:focus,
.hero-teaser .carousel__control-play:hover,
.hero-teaser .carousel__control-play:active {
  text-decoration: none;
}
.hero-teaser .carousel__icon-play {
  display: inline-block;
  font-size: 30px;
}
.hero-teaser .carousel__icon-pause {
  display: none;
  font-size: 30px;
}
.hero-teaser.carousel--sliding .carousel__icon-pause {
  display: inline-block;
}
.hero-teaser.carousel--sliding .carousel__icon-play {
  display: none;
}
.hero-teaser .carousel__control-forward,
.hero-teaser .carousel__control-backward,
.hero-teaser .carousel__indicators {
  z-index: 3;
}
.hero-teaser .edit-mode .carousel__control-forward,
.hero-teaser .edit-mode .carousel__control-backward {
  max-height: 50%;
}
.hero-teaser .edit-mode .carousel__item {
  display: block;
}
.iframe {
  margin-top: 20px;
}
.iframe iframe {
  max-width: 100%;
  width: 100%;
  height: 450px;
  border: none;
}
/* Acordeon-Element */
.accordeonContent ul.accordeon {
  list-style-type: none;
  list-style: none;
  list-style-image: none;
  padding: 0;
  margin: 0 0 1.875rem;
}
.accordeonContent ul.accordeon:last-child {
  margin-bottom: 0;
}
.accordeonContent ul.tabs ul.accordeon {
  list-style-type: none;
  list-style: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
}
ul.accordeon > li {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #000000;
}
ul.accordeon > li:last-child {
  border-bottom: none;
}
ul.accordeon > li > button {
  padding: 10px 0;
  margin: 0;
  display: block;
  position: relative;
  width: 100%;
  padding-left: 1.5rem;
  text-decoration: none;
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}
ul.accordeon > li > button > h3,
ul.accordeon > li > button > .accordeon__title {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--primary-color);
}
ul.accordeon > li > button span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0;
  color: var(--primary-color);
  font-size: 1.25rem;
  transform: translateY(-50%);
}
ul.accordeon:not(.edit-mode) > li > div {
  display: none;
  padding: 0.5rem;
}
/*  Ge�ffnettes Accordeon-Element*/
ul.accordeon:not(.edit-mode) > li.open > div {
  display: block;
}
/*  Minus-Icon wird angezeigt.*/
ul.accordeon > li.open > button span::before {
  content: "\002D";
}
/*
.accordeonContent ul.accordeon ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-red.svg");
}

.accordeonContent ul.accordeon ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-gray.svg");
}

.accordeonContent ul.accordeon ul ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/line-gray.svg");
}

// Listen-Icons in einem Akkordeon in einem Tab
.accordeonContent ul.tabs ul.accordeon ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-red.svg");
}

.accordeonContent ul.tabs ul.accordeon ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-gray.svg");
}

.accordeonContent ul.tabs ul.accordeon ul ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/line-gray.svg");
}
*/
.accordeonContent a.anchor-link {
  display: block;
  position: relative;
  top: -5rem;
}
.accordeon__toggle-btn {
  background: #e2002a;
  border: 1px solid #e2002a;
  padding: 0.625rem;
  color: #fff;
  text-decoration: none;
  margin: 10px 0 -30px auto;
  display: block;
  min-width: 6.25rem;
  z-index: 9;
  position: relative;
}
.accordeon__toggle-btn:hover,
.accordeon__toggle-btn:focus {
  background: #fff;
  color: #e2002a;
}
.accordeon__no-bullet-point {
  list-style: none;
}
.site-nav__menu-board {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.9);
  width: auto;
  padding-top: 1rem;
  left: auto;
  top: 50px;
  z-index: 10;
  box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.61);
  margin-top: 30px;
  overflow-y: auto;
  max-height: 80vh;
  scrollbar-width: thin;
}
@media screen and (min-width: 1280px) {
  .site-nav__menu-board {
    top: 75px;
  }
}
.site-nav__menu-board .col-1 {
  max-width: unset;
  padding: 0 1rem;
}
@media screen and (max-width: 919px) {
  .site-nav__menu-board {
    background: none;
    margin-top: 0;
    box-shadow: none;
    scrollbar-width: none;
  }
  .site-nav__menu-board .col-1 {
    max-width: 100%;
  }
  .site-nav__menu-board [class*="col-"] {
    overflow: unset;
    padding-left: unset;
  }
}
.site-nav__menu-board ul li {
  list-style-type: none;
}
.site-nav__menu-board ul li a {
  color: #ffffff;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 920px) {
  .site-nav__menu-board ul li a {
    font-size: 1rem;
  }
}
.site-nav__menu-board ul li a:hover {
  color: #ffffff;
}
.site-nav__menu-board .nasicher-icon {
  display: none;
}
@media screen and (min-width: 920px) {
  .site-nav__menu-board .nasicher-icon {
    display: block;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.3125rem;
  }
}
.site-nav__menu-board.show-submenu {
  display: block;
  position: relative;
  top: 0;
}
.site-nav__menu-board.show-submenu .row {
  display: block;
}
.site-nav__menu-item {
  border-bottom: 1px solid #ffffff;
}
.site-nav__menu-item .active-link {
  color: #ffffff;
}
.site-nav__menu-item:last-child {
  border-bottom: none;
}
.sublevel__parent-item {
  display: flex;
  margin: 1em 0;
  padding: 0 0 1rem 0;
  border-bottom: 1px solid white;
}
.sublevel__link {
  display: inline-block;
  padding: 8px 24px 8px 8px;
  white-space: nowrap;
  width: 100%;
}
.component--apprenticeships-teaser > .inner {
  padding: 0;
}
.component--apprenticeships-teaser .apprenticeships-teaser {
  padding: 6.25rem 3.125rem 1.875rem;
}
@media screen and (min-width: 920px) {
  .component--apprenticeships-teaser .apprenticeships-teaser {
    display: grid;
    grid-template-columns: 2.5fr 3fr;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .component--apprenticeships-teaser .apprenticeships-teaser {
    padding: 3.75rem 0;
  }
}
.next-level-teaser {
  padding: 4.0625rem 2.8125rem;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 3.75rem;
}
@media screen and (min-width: 920px) {
  .next-level-teaser {
    padding: 4.6875rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 0;
  }
}
.next-level-teaser .headline {
  font-size: 3.4375rem;
  line-height: 3.4375rem;
}
.next-level-teaser__button {
  margin-top: 3.125rem;
}
@media screen and (min-width: 920px) {
  .next-level-teaser__button {
    text-align: center;
    margin-top: 0;
  }
}
.next-level-teaser__button .button {
  font-size: 1.375rem;
}
