/* Acordeon-Element */
.accordeonContent ul.accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0 0 30/@px;

	&:last-child {
		margin-bottom: 0;
	}
}

.accordeonContent ul.tabs ul.accordeon {
	list-style-type: none;
	list-style: none;
	list-style-image:none;
	padding: 0;
	margin: 0;
}

ul.accordeon > li {
	padding: 0;
	margin: 0;
	border-bottom: 1px solid @color-black;

	&:last-child {
		border-bottom: none;
	}
}

ul.accordeon > li > button {
	padding: 10px 0;
	margin: 0;
	display: block;
	position: relative;
	width: 100%;
	padding-left: 24/@px;
	text-decoration: none;
	font-size: 16/@px;
	background: none;
	border: none;
	cursor: pointer;
	text-align: left;
}

ul.accordeon > li > button > h3,
ul.accordeon > li > button > .accordeon__title {
	padding: 0;
	margin: 0;
	font-size: 16/@px;
	line-height: 20/@px;
	color: var(--primary-color)
}

ul.accordeon > li > button span {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20/@px;
	height: 20/@px;
	position: absolute;
	top: 50%;
	left: 0;
	color: var(--primary-color);
	font-size: 20/@px;
	transform: translateY(-50%);
}

ul.accordeon:not(.edit-mode) > li > div {
	display: none;
	padding: 8/@px;
}

/*  Ge�ffnettes Accordeon-Element*/
ul.accordeon:not(.edit-mode) > li.open > div {
	display: block;
}

/*  Minus-Icon wird angezeigt.*/
ul.accordeon > li.open > button span::before {
	content: "\002D";
}

// Listen-Icons in einem Akkordeon
/*
.accordeonContent ul.accordeon ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-red.svg");
}

.accordeonContent ul.accordeon ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-gray.svg");
}

.accordeonContent ul.accordeon ul ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/line-gray.svg");
}

// Listen-Icons in einem Akkordeon in einem Tab
.accordeonContent ul.tabs ul.accordeon ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-red.svg");
}

.accordeonContent ul.tabs ul.accordeon ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/point-gray.svg");
}

.accordeonContent ul.tabs ul.accordeon ul ul ul {
	list-style-image: url("/file_source/corporate/default-theme/images/line-gray.svg");
}
*/

.accordeonContent a.anchor-link {
	display: block;
	position: relative;
	top: -80/@px; // breadcrump 40px + menubar 40px
}

.accordeon__toggle-btn {
	background: @color-oebb-red;
	border: 1px solid @color-oebb-red;
	padding: 10/@px;
	color: #fff;
	text-decoration: none;
	margin: 10px 0 -30px auto;
	display: block;
	min-width: 100/@px;
	z-index: 9;
	position: relative;

	&:hover,
	&:focus {
		background: #fff;
		color: @color-oebb-red;
	}
}

.accordeon__no-bullet-point {
	list-style: none;
}
