@px: 16rem; // define a base font size for converting px to rem

// .example {
//     font-size: 16/@px; --> 1rem
//     margin: 20/@px 0; --> 1.25rem 0
//     padding: 20/@px 10/@px; --> 1.25rem 0.625rem;
//   }

@screen-xs-min: ~"screen and (min-width: 576px)";
@screen-sm-min: ~"screen and (min-width: 768px)";
@screen-md-min: ~"screen and (min-width: 920px)";
@screen-lg-min: ~"screen and (min-width: 1280px)";
@screen-xl-min: ~"screen and (min-width: 1440px)";

@screen-xs-max: ~"screen and (max-width: 767px)";
@screen-sm-max: ~"screen and (max-width: 919px)";
@screen-md-max: ~"screen and (max-width: 1279px)";
@screen-lg-max: ~"screen and (max-width: 1439px)";

// Fonts 2024
@font-fallback: Arial, Helvetica, sans-serif;

// Different Font Families
.font-family-regular() {
    font-family: 'FrutigerNextLTW06-Regular', @font-fallback;
}

.font-family-medium() {
    font-family: 'FrutigerNextLTW06-Medium', @font-fallback;
}

.font-family-light() {
    font-family: 'FrutigerNextLTW06-Light', @font-fallback;
}

.font-family-bold() {
    font-family: 'FrutigerNextLTW06-Bold', @font-fallback;
}

/* Different font weights */

.font-weight-regular() {
    .font-family-regular();
    font-weight: 400;
}

.font-weight-medium() {
    .font-family-medium();
    font-weight: 500;
}

.font-weight-bold() {
    .font-family-regular();
    font-weight: 700;
}

.font-weight-heavy() {
    .font-family-bold();
    font-weight: 700;
}

.font-weight-black() {
    .font-family-bold();
    font-weight: 900;
}


@color-oebb-red: #e2002a; // rgb(226,0,42)
@color-railway-engineering: #24499A; // rgb(36,73,154);
@color-commercial: #472583; // rgb(71,37,131)
@color-technical: #EB5E5E; // rgb(235,94,94)

@gradient-railway-engineering: linear-gradient(245.77deg, rgba(36, 73, 154, 0) 0%, rgba(36, 73, 154, 0.6) 100.23%);
@gradient-commercial: linear-gradient(245.77deg, rgba(71, 37, 131, 0) 0%, rgba(71, 37, 131, 0.6) 100.23%);
@gradient-technical: linear-gradient(245.77deg, rgba(235, 94, 94, 0) 0%, rgba(235, 94, 94, 0.6) 100.23%);
@gradient-oebb-red: linear-gradient(245.77deg, rgba(226, 0, 42, 0) 0%, rgba(226, 0, 42, 0.6) 100.23%);


@color-black: #000000;
@color-white: #ffffff;

@border-radius-default: 30/@px;
@border-radius-big: 50/@px;

